import { useLocation, useNavigate } from '@remix-run/react';
import pluralize from 'pluralize';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import useSWRMutation from 'swr/mutation';
import { match } from 'ts-pattern';

import {
  type DtoBlock,
  type DtoGamePack,
  type DtoSharedAsset,
  type DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

import { type UGCAnalytics } from '../../../analytics/ugc';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type GamePack } from '../../../types/game';
import { fromDTOGamePack, fromMediaDTO } from '../../../utils/api-dto';
import { StoredValueChan } from '../../../utils/Chan';
import { err2s } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { AIIcon } from '../../icons/AIIcon';
import { Loading } from '../../Loading';
import { SharedAssetCover } from '../../SharedAsset';
import { GamePackCover } from '../Utilities';
import { activityGenAIAction } from './ActivityEditor';
import {
  UGCFileManagerProvider,
  useUGCFileManager,
} from './CustomGameFileManager';
import { CustomGamePackContainer } from './CustomGamePackLayout';
import { CustomGamePackPromptEditor } from './CustomGamePackPromptEditor';
import { CustomGameThinkingHint } from './CustomGameThinkingHint';
import { CustomGamePackHeader, CustomGamePackHeaderLeft } from './Nav';
import { type Activity, type ActivityGenAIRunContext } from './types';
import {
  generateName,
  log,
  UGCUtils,
  useAbortableBlockGenAI,
  useUGCAnalytics,
} from './utils';

function Introduction(props: { template: DtoGamePack }) {
  return (
    <div
      className='w-full px-8 py-7.5 bg-modal 
          border border-secondary rounded-xl flex flex-col'
    >
      <div className='flex items-start gap-9'>
        <div className='flex-shrink-0 w-56'>
          <GamePackCover pack={fromDTOGamePack(props.template)} />
        </div>
        <div className='flex flex-col gap-6'>
          <div className='font-bold'>{props.template.name}</div>
          <div className='text-sms'>
            {props.template.ugcSettings?.userDirection}
          </div>
        </div>
      </div>
    </div>
  );
}

function RightPanel(props: { template: DtoGamePack; onCreate: () => void }) {
  return (
    <div className='w-full h-full flex items-end justify-end'>
      <button
        type='button'
        className='text-icon-gray font-medium mr-8 mb-4 hover:text-white'
        onClick={props.onCreate}
      >
        Create Manually
      </button>
    </div>
  );
}

function ActivityPreview(props: { activity: Activity }) {
  const { activity } = props;
  const { asset } = activity;

  return (
    <div
      className='w-full min-h-21 bg-modal border border-secondary rounded-xl 
    flex items-center p-2.5 gap-3.5'
    >
      <div className='w-28 flex-shrink-0' style={{ aspectRatio: '16/9' }}>
        <SharedAssetCover
          media={fromMediaDTO(asset.media)}
          className='w-full h-full'
        />
      </div>
      <div className='h-full flex flex-col gap-2'>
        <p className='text-white text-xl font-bold'>{asset.primaryText}</p>
        <p className='text-secondary'>{asset.secondaryText}</p>
      </div>
    </div>
  );
}

function CreatedGamePack(props: {
  pack: DtoGamePack | GamePack | undefined;
  blocks: DtoBlock[];
  sharedAssets: DtoSharedAsset[];
  name: string;
}) {
  const { pack, blocks, sharedAssets } = props;

  const activities = useMemo(
    () => UGCUtils.MakeActivities(pack, blocks, sharedAssets),
    [pack, blocks, sharedAssets]
  );

  if (!pack) return null;

  return (
    <div className='w-full flex flex-col items-center justify-center gap-3 self-start'>
      <p className='text-xl font-bold text-center'>
        Your <span className='text-tertiary'>{props.name}</span> Game Was
        <br />
        Generated Successfully
      </p>
      <p className='text-secondary'>
        There {activities.length === 1 ? 'is' : 'are'} {activities.length}{' '}
        {pluralize('activity', activities.length)} that{' '}
        {activities.length === 1 ? 'makes' : 'make'} up this game
      </p>
      <div className='w-full flex flex-col gap-2.5'>
        {activities.map((a) => (
          <ActivityPreview key={a.block.id} activity={a} />
        ))}
      </div>
    </div>
  );
}

function CreateCustomGamePackModal(props: {
  creator: () => Promise<DtoSingleGamePackResponse>;
  templateId: string;
  name: string;
  onConfirm: () => void;
  onClose: () => void;
}) {
  const { creator, templateId, name } = props;
  const location = useLocation();

  const swr = useSWRMutation(`/game-packs/${templateId}/customize`, creator);

  useEffectOnce(() => {
    swr.trigger();
  });

  const onContinue = () => {
    if (!swr.data?.gamePack) return;
    // We will see the empty page after navigate and close the modal.
    // Use _window.location.replace_ instead.
    const params = new URLSearchParams(location.search);
    params.set('created', 'true');
    window.location.replace(
      `/custom-games/${swr.data.gamePack.id}/edit?${params.toString()}`
    );
  };

  return (
    <ModalWrapper borderStyle='gray' containerClassName='w-180'>
      <div className='w-full min-h-120 flex flex-col items-center justify-center px-15 py-10 relative gap-10'>
        <div className='w-full flex-1 flex items-center justify-center'>
          {match(swr)
            .when(
              () => swr.isMutating,
              () => (
                <Loading
                  containerClassName='flex-col'
                  imgClassName='w-20 h-20'
                  text='Creating in Progress...'
                />
              )
            )
            .when(
              () => !!swr.error,
              () => (
                <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
              )
            )
            .otherwise(() => (
              <CreatedGamePack
                pack={swr.data?.gamePack}
                name={name}
                blocks={swr.data?.blocks ?? []}
                sharedAssets={swr.data?.linkedSharedAssets ?? []}
              />
            ))}
        </div>
        <div className='flex items-center justify-center'>
          <button
            type='button'
            className='btn-primary w-40 h-10'
            disabled={swr.isMutating || !!swr.error || !swr.data?.gamePack}
            onClick={onContinue}
          >
            Customize
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function CustomGamePackStarterInternal(props: {
  template: DtoGamePack;
  name: string;
  enableAI: boolean;
  analytics: UGCAnalytics;
  onNameChange: (name: string) => void;
}) {
  const { template, name, analytics } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const openModal = useLiveCallback(
    async (creator: () => Promise<DtoSingleGamePackResponse>) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <CreateCustomGamePackModal
            creator={creator}
            templateId={template.id}
            name={name}
            onClose={p.internalOnCancel}
            onConfirm={p.internalOnConfirm}
          />
        ),
      });
    }
  );

  // ensure only one game pack will be created
  const st = useRef<'none' | 'inProgress' | 'done' | 'failed'>('none');
  const chan = useInstance(
    () => new StoredValueChan<DtoSingleGamePackResponse>()
  );
  const createGamePack = useLiveCallback(async () => {
    if (st.current === 'inProgress' || st.current === 'done') {
      return chan.take();
    }
    st.current = 'inProgress';
    try {
      const resp = await apiService.gamePack.customize(template.id, {
        name,
      });
      st.current = 'done';
      chan.put(resp.data);
      return chan.take();
    } catch (error) {
      st.current = 'failed';
    }
  });

  const generatePackName = useLiveCallback(
    async (packId: string, prompt: string, signal?: AbortSignal | null) => {
      try {
        const resp = await generateName(
          packId,
          { userPrompt: prompt },
          { signal }
        );
        props.onNameChange(resp.data.name);
      } catch (error) {
        log.error('Failed to generate game pack name', error, {
          packId,
          prompt,
        });
      }
    }
  );

  const { generate, abort, state, error } = useAbortableBlockGenAI(
    async (prompt: string, signal: AbortSignal) => {
      try {
        const resp = await createGamePack();
        if (!resp) return false;
        const originalPrompt = prompt;
        let optimizedPrompt = prompt;

        try {
          const optimizePromptResp =
            await apiService.gamePack.optimizeUGCPrompt(resp.gamePack.id, {
              prompt,
            });
          optimizedPrompt = optimizePromptResp.data.prompt;
        } catch (error) {
          log.error('Failed to optimize prompt', error);
        }

        analytics.trackCustomGameAIPrompt({
          packId: resp.gamePack.id,
          prompt: originalPrompt,
          optimizedPrompt:
            originalPrompt === optimizedPrompt ? 'N/A' : optimizedPrompt,
        });
        const runGroups = UGCUtils.MakeRunGroups(
          resp.gamePack,
          resp.blocks ?? []
        );
        const promises = [
          generatePackName(resp.gamePack.id, optimizedPrompt, signal),
        ];
        // Run the group in parallel, but run the blocks inside group in sequence.
        for (const blocks of runGroups) {
          promises.push(
            new Promise<void>(async (resolve, reject) => {
              const groupContext: ActivityGenAIRunContext = {};
              for (const block of blocks) {
                try {
                  const blockContext = await activityGenAIAction({
                    packId: resp.gamePack.id,
                    block,
                    prompt: optimizedPrompt,
                    signal,
                    ctx: groupContext,
                  });
                  Object.assign(groupContext, blockContext);
                } catch (error) {
                  reject(error);
                  return;
                }
              }
              resolve();
            })
          );
        }
        await Promise.all(promises);
        openModal(async () => resp);
        return true;
      } catch (error) {
        log.error('Failed to create game pack with AI', error, {
          prompt,
          templatePackId: template.id,
        });
        throw error;
      }
    }
  );

  const createManually = useLiveCallback(async () => {
    const resp = await apiService.gamePack.customize(template.id, {
      name,
    });

    analytics.trackCustomGameManualPrompt({
      packId: resp.data.gamePack.id,
    });
    return resp.data;
  });

  useEffectOnce(() => {
    if (props.enableAI) return;
    openModal(createManually);
  });

  const fileman = useUGCFileManager();
  const initFileman = useLiveCallback(async () => {
    if (fileman.inited) return;
    const resp = await createGamePack();
    if (!resp) return;
    fileman.emitMessage('A new game pack created.');
    fileman.init(resp.gamePack.id, []);
  });

  useEffect(() => {
    fileman.uppy.addPreProcessor(initFileman);
    return () => fileman.uppy.removePreProcessor(initFileman);
  }, [fileman.uppy, initFileman]);

  return (
    <CustomGamePackContainer
      right={
        <RightPanel {...props} onCreate={() => openModal(createManually)} />
      }
    >
      <div className='w-full h-full flex flex-col items-center text-white'>
        <div className='absolute left-1/2 top-1/3 z-5 text-white transform -translate-x-1/2 -translate-y-1/2'>
          {state.isRunning ? (
            <CustomGameThinkingHint />
          ) : (
            <div className='w-160'>
              <div className='flex items-center justify-center gap-4 mb-7.5'>
                <AIIcon className='w-6 h-6 fill-current' />
                <p className='text-xl font-bold'>
                  Let’s customize your game with AI!
                </p>
              </div>
              <Introduction {...props} />
            </div>
          )}
        </div>
        <div className='w-160 h-full flex flex-col items-center mt-34 mb-2'>
          <CustomGamePackPromptEditor
            enabled={props.enableAI}
            onSubmit={generate}
            onAbort={abort}
            isSubmitting={state.isRunning}
            wrapperClassName='mt-auto py-4'
            active
            autoFocus
            error={error}
            placeholder='Generate a game of ...'
          />
        </div>
      </div>
    </CustomGamePackContainer>
  );
}

export function CustomGamePackStarter(props: {
  template: DtoGamePack;
  defaultName: string;
  enableAI: boolean;
}) {
  const nagivate = useNavigate();
  const analytics = useUGCAnalytics();
  const [name, setName] = useState(props.defaultName);

  return (
    <Fragment>
      <CustomGamePackHeader
        left={
          <CustomGamePackHeaderLeft
            title={name}
            onClickBack={() => nagivate(-1)}
            onTitleChange={(t) => setName(t)}
          />
        }
      />
      <UGCFileManagerProvider>
        <CustomGamePackStarterInternal
          {...props}
          name={name}
          analytics={analytics}
          onNameChange={setName}
        />
      </UGCFileManagerProvider>
    </Fragment>
  );
}
